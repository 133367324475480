<template>
    <div class="app-input"
        :class="{
            disabled,
            error,
            required,
            typing,
            focused,
            [textTransform]: textTransform,
            [child_class]: child_class,
            'with-toggle-password-visibility-button': show_toggle_password_visibility_button,
            'with-clear-button': show_clear_button,
        }"
        @click="focus"
    >
        <i class="icon icon-before" :class="`icon-${ icon }`" v-if="icon"></i>

        <div v-if="error" class="error-message" :class="{ crop: !wrapErrorMessage }"><i></i><span>{{ error }}</span></div>
        <label v-else><span>{{ label }}<i v-if="required">*</i></span></label>

        <input
            :type="computedType"
            :value="value"
            :disabled="disabled"
            :maxlength="maxlength"
            :placeholder="placeholder"
            :readonly="readonly"
            :autocomplete="autocomplete === true ? 'on' : autocomplete === false ? 'off' : null"

            :min="min"
            :max="max"

            ref="field"

            @focus="handleFocus"
            @blur="handleBlur"
            @keyup.enter="onEnter"
            @input="handleChange($event.target.value)"
        >

        <button v-if="show_toggle_password_visibility_button"
            class="btn-toggle-password-visibility"
            :class="{ 'hide-password': password_visibility }"

            @click.prevent="togglePasswordVisibility"
        ></button>

        <button v-if="show_clear_button"
            class="btn-clear"

            @click="clear"
        ></button>

        <app-password-helper v-if="passwordHelper && focused"
            :value="value"
            :password-rules="passwordHelperRules"
        />

        <div class="characters-counter" v-if="charactersCounter && focused">
            <span>{{ characters_counter }}</span>
        </div>
    </div>
</template>

<script>
/*
    @todo
    handleLabelClick(event) {
        if (this.selected) {
            event.stopPropagation()
        }
    },
*/

import Inputmask from 'inputmask';

import appPasswordHelper from '@/components/app-password-helper'

const DEFAULT_PASSWORD_HELPER_RULES = [
    {
        pattern: /^.{8,}/,
        message: '8 characters',
    },
    {
        pattern: /[A-Z]/,
        message: '1 upper case',
    },
    {
        pattern: /[a-z]/,
        message: '1 lower case',
    },
    {
        pattern: /[0-9]/,
        message: '1 digit',
    },
]

export default {
    props: {
        value:          { required: false                             },
        type:           { type: String,           default: 'text'     },
        prefix:         { type: String,           default: ''         },
        reference:      { type: String,           default: ''         },
        required:       { type: Boolean,          default: false      },
        disabled:       { type: Boolean,          default: false      },
        error:          {                         default: false      },
        mask:           { type: [Object, String], default: null       },
        label:          { type: String,           default: ''         },
        maxlength:      {                         default: 100        },
        placeholder:    {                         default: ''         },
        textTransform:  {                         default: ''         },
        debounce:       { type: Number,           default: 0          },
        readonly:       { type: Boolean,          default: false      },
        autocomplete:   { type: Boolean,          default: null       },

        min: { type: Number },
        max: { type: Number },

        passwordHelper: { type: Boolean,          default: false      },
        passwordHelperRules: { type: Array, default: () => DEFAULT_PASSWORD_HELPER_RULES },

        withTogglePasswordVisibility: { type: Boolean, default: false },

        showClearButton: { type: Boolean, default: false },

        wrapErrorMessage: { type: Boolean, default: false },

        charactersCounter: { type: Boolean, default: false },

        icon: { type: String, default: null },
    },

    components: {
        appPasswordHelper,
    },

    data() {
        return {
            focused: false,
            password_visibility: false,
            debounce_timeout: null,
            child_class: null,
            have_mask_static_characters: false,
        }
    },

    mounted() {
        if (this.mask) {
            const default_options = {
                placeholder: '',

                jitMasking: true,
                clearMaskOnLostFocus: false,

                oncomplete: () => this.handleChange(this.$refs.field.value),
                oncleared:  () => this.handleChange(this.$refs.field.value),
            }

            const im = typeof this.mask == 'object'
                ? new Inputmask({ ...default_options, ...this.mask })
                : new Inputmask(this.mask, { ...default_options })

            const im_instance = im.mask(this.$refs.field)

            this.have_mask_static_characters = im_instance.getemptymask().length > 0
        }
    },

    methods: {
        focus() {
            this.$refs.field.focus()

            return this
        },

        select() {
            this.$refs.field.select()

            return this
        },

        handleFocus() {
            this.focused = true

            this.$emit('onfocus')
        },

        handleBlur() {
            this.focused = false

            this.$emit('onblur')
        },

        handleChange(valueraw) {
            let value = valueraw
            if (this.prefix && value && value.startsWith(this.prefix)) {
                value = value.substring(this.prefix.length)
            }
            this.$emit('input', value)
            this.emitDebounce('change', value)

            this.emitDebounce('detailchange', {
                reference:     this.reference,
                type:          this.type,
                required:      this.required,
                disabled:      this.disabled,
                error:         this.error,
                label:         this.label,
                placeholder:   this.placeholder,
                error:         this.error,
                oldValue:      this.value,
                newValue:      value,
            })
        },

        onEnter($event) {
            this.$emit('input',   $event.target.value)
            this.$emit('change',  $event.target.value)
            this.$emit('onenter', $event.target.value)

            this.clearDebounce()
        },

        emitDebounce(event, value) {
            if (this.debounce) {
                this.clearDebounce()
    
                this.debounce_timeout = setTimeout(() => {
                    this.$emit(event, value)
                    this.debounce_timeout = null
                }, this.debounce)
            } else {
                this.$emit(event, value)
            }
        },
        clearDebounce() {
            if (this.debounce_timeout !== null) {
                clearTimeout(this.debounce_timeout)

                this.debounce_timeout = null
            }
        },

        safeToString(x) {
            switch (typeof x) {
                case 'object':
                    return '';
                case 'function':
                    return '';
                default:
                    return x + '';
            }
        },

        addPrefix(val) {
            const strval = this.safeToString(val)
            if (strval && strval != '' && !strval.startsWith(this.prefix)) {
                return this.prefix + strval
            }
            return val
        },

        togglePasswordVisibility() {
            this.setPasswordVisibility(!this.password_visibility)

            this.$emit('toggle-password-visibility', this.password_visibility)
        },

        setPasswordVisibility(password_visibility) {
            this.password_visibility = password_visibility
        },

        clear() {
            const value = ''

            this.$emit('input',  value)
            this.$emit('change', value)
            this.$emit('clear',  value)

            this.clearDebounce()
        },
    },

    computed: {
       typing() {
           return this.focused || (this.value || this.value === 0) || this.have_mask_static_characters
       },

        computedType() {
            return this.password_visibility ? 'text' : this.type
        },

        show_toggle_password_visibility_button() {
            return this.type == 'password' && this.withTogglePasswordVisibility
        },

        show_clear_button() {
            return this.showClearButton && typeof this.value == 'string' && this.value.length > 0
        },

        characters_counter() {
            const length = typeof this.value == 'string' ? this.value.length : 0

            const characters_counter = `${ length } / ${ this.maxlength }`

            return characters_counter
        },
    },
}
</script>

<style lang="scss">
$app-input-padding-x: 14px;
$app-input-icon-width: 42px;

.app-input {
    position: relative;

    width: 100%;
    min-height: 48px;

    border-radius: $border-radius-primary;
    background: var(--color-input-bg);

    input {
        display: block;

        width: 100%;

        padding: 11px $app-input-padding-x;

        font-size: 16px;
        line-height: 24px;
        font-weight: normal;
        color: var(--color-text-primary);

        border-radius: $border-radius-primary;
        border: solid 1px var(--color-input-border);

        &::placeholder {
            color: transparent;
        }

        &[type=password] {
            letter-spacing: 2px;

            &::placeholder {
                letter-spacing: inherit;
            }
        }

        &:hover {
            border-color: var(--color-input-border-hover);
        }
    }

    label {
        position: absolute;
        top: 50%;
        left: $app-input-padding-x;

        max-width: calc(100% - 2 * #{ $app-input-padding-x } - 1px);

        padding: 0 4px;

        font-size: 16px;
        font-weight: normal;
        color: var(--color-input-label);

        transform: translateY(-50%);

        transition: $transition-duration-primary;

        span {
            position: relative;

            display: block;

            @include text-overflow();
        }

        i {
            color: var(--color-error);
            font-style: normal;
        }

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 40%;

            background-color: var(--color-input-bg);

            content: '';
        }
    }

    .characters-counter {
        position: absolute;
        bottom: 0;
        right: 14px;

        max-width: calc(100% - 2 * 14px - 1px);

        padding: 0 4px;

        font-size: 14px;
        font-weight: normal;
        color: var(--color-input-label-typing);

        transform: translateY(50%);

        span {
            position: relative;

            display: block;

            @include text-overflow();
        }

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            background-color: var(--color-component-bg-primary);

            content: '';
        }
    }

    .error-message {
        width: 100%;
        max-width: 100%;

        font-size: 14px;
        line-height: 24px;
        color: var(--color-error);

        i {
            position: absolute;
            top: 0;
            left: 0;

            display: inline-block;

            width: 24px;
            height: 24px;

            @include icon-before($icon-attention);
        }

        span {
            display: inline-block;

            text-indent: 24px;
        }

        &.crop {
            position: absolute;
            bottom: 100%;
            left: 0;

            display: flex;

            span {
                @include text-overflow();
            }
        }
    }

    .icon {
        position: absolute;
        top: 1px;
        left: calc(100% - 50px);
        right: 1px;
        bottom: 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--color-component-bg-primary);
        border-radius: $border-radius-primary;

        &.icon-before {
            right: auto;
            left: 1px;
            width: $app-input-icon-width;

            &+label {
                left: $app-input-icon-width;
                max-width: calc(100% - 2 * #{ $app-input-icon-width } - 1px);
            }

            &~input {
                padding-left: $app-input-icon-width;
            }
        }

        &.icon-search {
            @include icon-before($icon-search);
        }
    }

    .btn-toggle-password-visibility,
    .btn-clear {
        position: absolute;
        top: 50%;
        right: 16px;

        transform: translateY(-50%);

        display: block;

        width: 24px;
        height: 24px;

        color: var(--color-table-action-secondary);

        transition: $transition-duration-primary;

        &:hover {
            color: var(--color-table-action-secondary-hover);
        }

        &:active {
            color: var(--color-table-action-secondary-active);
        }
    }

    .btn-toggle-password-visibility {
        @include icon-before($icon-input-show-password);

        &.hide-password {
            @include icon-before-code($icon-input-hide-password);
        }
    }

    .btn-clear {
        @include icon-before($icon-input-clear);
    }

    &.with-toggle-password-visibility-button,
    &.with-clear-button {
        input {
            padding-right: 16px + 24px + 16px;
        }
    }

    &.typing {
        label {
            top: 0;
            font-size: 14px;
            color: var(--color-input-label-typing);

            i {
                color: inherit;
            }

            &:before {
                top: 45%;
            }
        }

        input {
            border-color: var(--color-input-border-active);

            &::placeholder {
                color: var(--color-input-placeholder);
            }
        }

        
        .icon {
            &.icon-before {
                &+label {
                    left: $app-input-padding-x;
                }
            }
        }
    }

    &.disabled {
        opacity: 0.4;
        pointer-events: none;
        cursor: default;
    }

    &.error {
        input {
            color: var(--color-error);
            border: 1px solid var(--color-input-border-error);
        }

        label {
            color: var(--color-error);
        }
    }

    &.uppercase {
        input {
            text-transform: uppercase
        }
    }

    &.lowercase {
        input {
            text-transform: lowercase
        }
    }

    &.capitalize {
        input {
            text-transform: capitalize
        }
    }
}

@media (max-width: $mobile-size) {
    .app-input {
        input {
            font-size: 14px;
        }

        label {
            font-size: 14px;
        }

        &.typing {
            label {
                font-size: 12px;
            }
        }

        .characters-counter {
            font-size: 12px;
        }
    }
}
</style>