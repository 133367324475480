var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-input",class:( _obj = {
        disabled: _vm.disabled,
        error: _vm.error,
        required: _vm.required,
        typing: _vm.typing,
        focused: _vm.focused
    }, _obj[_vm.textTransform] = _vm.textTransform, _obj[_vm.child_class] = _vm.child_class, _obj['with-toggle-password-visibility-button'] =  _vm.show_toggle_password_visibility_button, _obj['with-clear-button'] =  _vm.show_clear_button, _obj ),on:{"click":_vm.focus}},[(_vm.icon)?_c('i',{staticClass:"icon icon-before",class:("icon-" + _vm.icon)}):_vm._e(),(_vm.error)?_c('div',{staticClass:"error-message",class:{ crop: !_vm.wrapErrorMessage }},[_c('i'),_c('span',[_vm._v(_vm._s(_vm.error))])]):_c('label',[_c('span',[_vm._v(_vm._s(_vm.label)),(_vm.required)?_c('i',[_vm._v("*")]):_vm._e()])]),_c('input',{ref:"field",attrs:{"type":_vm.computedType,"disabled":_vm.disabled,"maxlength":_vm.maxlength,"placeholder":_vm.placeholder,"readonly":_vm.readonly,"autocomplete":_vm.autocomplete === true ? 'on' : _vm.autocomplete === false ? 'off' : null,"min":_vm.min,"max":_vm.max},domProps:{"value":_vm.value},on:{"focus":_vm.handleFocus,"blur":_vm.handleBlur,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onEnter.apply(null, arguments)},"input":function($event){return _vm.handleChange($event.target.value)}}}),(_vm.show_toggle_password_visibility_button)?_c('button',{staticClass:"btn-toggle-password-visibility",class:{ 'hide-password': _vm.password_visibility },on:{"click":function($event){$event.preventDefault();return _vm.togglePasswordVisibility.apply(null, arguments)}}}):_vm._e(),(_vm.show_clear_button)?_c('button',{staticClass:"btn-clear",on:{"click":_vm.clear}}):_vm._e(),(_vm.passwordHelper && _vm.focused)?_c('app-password-helper',{attrs:{"value":_vm.value,"password-rules":_vm.passwordHelperRules}}):_vm._e(),(_vm.charactersCounter && _vm.focused)?_c('div',{staticClass:"characters-counter"},[_c('span',[_vm._v(_vm._s(_vm.characters_counter))])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }