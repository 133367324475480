<template>
    <div class="app-password-helper">
        <div class="validator">
            <div v-for="(rule, index) in rules" :key="index" :class="{ valid: rule.valid }">{{ rule.message }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value:         { default: null               },
        passwordRules: { type: Array, required: true },
    },

    computed: {
        rules() {
            return this.passwordRules.map(rule => ({
                valid: Boolean(this.value) && rule.pattern.test(this.value),
                message: rule.message,
            }))
        },
    },
}
</script>

<style lang="scss">
$helper-offset: 8px;
$helper-padding: 12px;
$helper-line-height: 24px;
$helper-tongue-size: $helper-padding;
$helper-tongue-offset: 0;

.app-password-helper {
    width: calc( 100% - #{$helper-offset} );
    height: 0;
    position: absolute;
    top: 0;
    left: $helper-offset;

    .validator {
        width: auto;
        min-width: 160px;
        font-size: 16px;
        line-height: $helper-line-height;
        padding: $helper-padding;
        position: absolute;
        left: 0;
        bottom: $helper-tongue-size;
        border-radius: $border-radius-secondary;
        border-bottom-left-radius: 0;
        background: $color-white;
        filter: drop-shadow( 0 4px 5px rgba(0, 0, 0, 0.32) );

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 100%;
            width: $helper-tongue-size;
            height: $helper-tongue-size;
            background: radial-gradient(circle at bottom right, transparent 0, transparent $helper-tongue-size, $color-white $helper-tongue-size, $color-white);
            left: 0;
        }

        &>div {
            display: flex;
            align-items: center;

            @include icon-before($icon-circle-block, 22px);
            &:before {
                color: $color-red;
                margin-right: 5px;
            }

            &.valid {
                color: $color-grey-darker;

                @include icon-before($icon-circle-checkmark, 16px);
                &:before {
                    color: $color-green;
                    margin-left: 3px;
                    margin-right: 8px;
                    margin-top: -2px;
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .app-password-helper {
        .validator {
            font-size: 14px;
        }
    }
}
</style>